// src/contexts/userContext.js

import React, { createContext, useState, useContext, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import authApiClient from '../authApiClient';

const UserContext = createContext();

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const isMountedRef = useRef(true);
  const location = useLocation(); // Use useLocation to get the current path

  const login = async (userData) => {
    try {
      setUser({
        ...userData,
        subscriptionActive: userData.subscriptionActive || false,
        subscriptionPlan: userData.subscriptionPlan || null
      });
      setIsLoading(false);
    } catch (error) {
      console.error('[userContext] Login error:', error);
      setIsLoading(false);
    }
  };

  const logout = () => {
    setUser(null);
    // Additional logout logic if necessary
  };

  const checkAuth = async () => {
    console.log('[userContext] checkAuth: Start - Checking user authentication state');
    console.log('[userContext] checkAuth: Current path is:', location.pathname);
  
    // Updated public paths - remove /app from public paths
    const publicPaths = ['/', '/about', '/contact', '/login', '/signup', '/pricing', '/payment-success'];
    const currentPath = location.pathname;
  
    // Skip auth check if the current path is public
    if (publicPaths.includes(currentPath)) {
      console.log(`[userContext] checkAuth: Skipping authentication check for public path: ${currentPath}`);
      if (isMountedRef.current) {
        setIsLoading(false);
      }
      return;
    }
  
    // All /app routes require authentication
    if (currentPath.startsWith('/app')) {
      console.log('[userContext] checkAuth: Authentication required for /app route');
      try {
        const response = await authApiClient.get('/users/me');
        const userData = {
          ...response.data,
          subscriptionActive: response.data.subscriptionActive || false,
          subscriptionPlan: response.data.subscriptionPlan || null
        };
        
        if (isMountedRef.current) {
          setUser(userData);
          setIsLoading(false);
        }
        
        // If no subscription, redirect to pricing
        if (!userData.subscriptionActive && currentPath !== '/pricing') {
          window.location.href = '/pricing';
        }
        
        return userData;
      } catch (error) {
        console.error('[userContext] Auth check error:', error);
        if (isMountedRef.current) {
          setUser(null);
          setIsLoading(false);
        }
        return null;
      }
    }
  };
  

  useEffect(() => {
    console.log('[userContext] useEffect: Component mounted or location changed, checking authentication');

    checkAuth();

    return () => {
      console.log('[userContext] useEffect cleanup: Component unmounting');
      isMountedRef.current = false;
    };
  }, [location.pathname]); // Depend on location.pathname

  console.log('[userContext] Current state - user:', user, 'isLoading:', isLoading);

  return (
    <UserContext.Provider value={{ user, setUser, login, logout, checkAuth, isLoading }}>
      {children}
    </UserContext.Provider>
  );
};

