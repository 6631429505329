import React from 'react';
import { Navigate } from 'react-router-dom';
import { useUser } from './contexts/userContext';
import LoadingScreen from './LoadingScreen';

const AdminRoute = ({ children }) => {
    const { user, isLoading } = useUser();
    
    console.log('[AdminRoute] User state:', user);
    console.log('[AdminRoute] Loading state:', isLoading);

    if (isLoading) {
        console.log('[AdminRoute] Still loading, showing LoadingScreen');
        return <LoadingScreen />;
    }

    if (!user) {
        console.log('[AdminRoute] No user found, redirecting to login');
        return <Navigate to="/login" replace />;
    }

    if (user.role !== 'admin') {
        console.log('[AdminRoute] User is not admin, redirecting to app');
        return <Navigate to="/app" replace />;
    }

    console.log('[AdminRoute] User is admin, rendering admin page');
    return children;
};

export default AdminRoute; 