/**
 * PaymentSuccessPage.js
 * --------------------------------------------------
 * This component is triggered by Stripe redirecting
 * the user back to /payment-success?session_id=...
 *
 * Primary Responsibilities:
 * - Verify the Stripe payment using our backend endpoint.
 * - Update the user's subscription status in the front-end
 *   context with the full user object (so we don't lose `id`).
 * - Navigate the user to /app upon success.
 * --------------------------------------------------
 */

import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import LoadingScreen from './LoadingScreen';
import authApiClient from './authApiClient';
import { useUser } from './contexts/userContext';

const PaymentSuccessPage = () => {
  // --------------------------------------------------
  // (A) State & Hooks
  // --------------------------------------------------
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get('session_id');

  const navigate = useNavigate();
  const location = useLocation();

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  // Our userContext
  const { user, isLoading: isUserLoading, setUser } = useUser();

  // A ref to track if component is still mounted
  const isMounted = useRef(true);

  // --------------------------------------------------
  // (B) Cleanup to mark unmounted
  // --------------------------------------------------
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  // --------------------------------------------------
  // (C) Main Payment Verification
  // --------------------------------------------------
  useEffect(() => {
    const verifyPayment = async () => {
      // 1) Make sure we have a session ID
      if (!sessionId) {
        console.error('[PaymentSuccess] No session ID found');
        if (isMounted.current) navigate('/pricing');
        return;
      }

      // 2) (Optional) Check for userId we stored in localStorage
      const storedUserId = localStorage.getItem('signup_user_id');
      console.log('[PaymentSuccess] Stored user ID:', storedUserId);

      // 3) Verify payment via the backend
      try {
        const verifyResponse = await authApiClient.get('/stripe_pricing/verify-payment', {
          params: {
            session_id: sessionId,
            user_id: storedUserId
          }
        });

        // 4) If payment verified, set user context
        if (verifyResponse.data.success) {
          if (isMounted.current) {
            // Instead of merging only subscription info, we set
            // the FULL user object from verifyResponse.data.user.
            const verifiedUser = verifyResponse.data.user;

            setUser(verifiedUser);

            // Clean up localStorage
            localStorage.removeItem('signup_user_id');

            // Remove the ?session_id param from the URL
            window.history.replaceState({}, document.title, '/app');

            // Stop loading
            setLoading(false);

            // Navigate to /app
            navigate('/app', { replace: true });
          }
        } else {
          // The backend returned success=false
          throw new Error(verifyResponse.data.error || 'Payment verification failed');
        }
      } catch (err) {
        if (isMounted.current) {
          console.error('[PaymentSuccess] Error details:', {
            message: err.message,
            response: err.response?.data,
            status: err.response?.status
          });
          setLoading(false);
          setError(
            err.response?.data?.error ||
            err.message ||
            'There was an error verifying your payment. Please contact support.'
          );
        }
      }
    };

    // Wait until user context has finished checking auth
    if (!isUserLoading) {
      verifyPayment();
    }
  }, [sessionId, navigate, isUserLoading, user, setUser]);

  // --------------------------------------------------
  // (D) Optional Fallback Check
  // --------------------------------------------------
  // If you want to ensure that /payment-success doesn't
  // redirect to /login right away if user is null:
  useEffect(() => {
    // If I'm on /payment-success, do not forcibly go /login yet
    if (location.pathname === '/payment-success') return;

    // Otherwise, if we're not loading and have no user, go login
    if (!isUserLoading && !user) {
      navigate('/login');
    }
  }, [location, user, isUserLoading, navigate]);

  // --------------------------------------------------
  // (E) Render Logic
  // --------------------------------------------------
  // 1) Show loading spinner while verifying
  if (loading || isUserLoading) {
    return <LoadingScreen />;
  }

  // 2) If error, show it
  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <div className="max-w-md w-full space-y-8 p-6 bg-white rounded-xl shadow-lg">
          <div className="text-center">
            <h2 className="text-xl font-bold text-gray-900 mb-2">Payment Verification Error</h2>
            <p className="text-red-500">{error}</p>
            <button
              onClick={() => navigate('/pricing')}
              className="mt-4 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
            >
              Return to Pricing
            </button>
          </div>
        </div>
      </div>
    );
  }

  // 3) Otherwise, fallback: a loading screen
  return <LoadingScreen />;
};

export default PaymentSuccessPage;
