/**
 * authApiClient.js
 * 
 * This file creates and exports a custom Axios instance that handles authentication
 * and token management for API requests in our application.
 * 
 * Key features:
 * 1. Centralized API configuration: Sets up a base URL for all API requests.
 * 2. Automatic token injection: Adds the authentication token to every outgoing request.
 * 3. Token refresh mechanism: Automatically refreshes the access token if it expires.
 * 4. Error handling: Manages authentication errors and redirects to login when needed.
 * 
 * How it works:
 * - The Axios instance is created with a base URL from the environment variables.
 * - A request interceptor adds the authentication token to each request's headers.
 * - A response interceptor catches 401 (Unauthorized) errors.
 * - If a 401 error occurs, it attempts to refresh the token using the refresh token.
 * - If the token refresh is successful, it retries the original request.
 * - If the refresh fails, it clears the authentication cookies and redirects to the login page.
 * 
 * Usage:
 * Import this client in your components or services and use it for making API calls.
 * It will handle authentication automatically, allowing you to focus on the API logic.
 * 
 * Example:
 * import authApiClient from './authApiClient';
 * const response = await authApiClient.get('/some-endpoint');
 * 
 * Note: Ensure that your backend supports the token refresh mechanism as implemented here.
 */

// src/authApiClient.js

import axios from 'axios';
import Cookies from 'js-cookie';

console.log('[authApiClient] Module initialization with baseURL:', process.env.REACT_APP_API_BASE_URL);

const authApiClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true,
});

// Request Interceptor
authApiClient.interceptors.request.use(
  (config) => {
    console.log('[authApiClient] Making request:', {
      method: config.method,
      url: config.url,
      withCredentials: config.withCredentials
    });
    // Add a timestamp to prevent duplicate requests
    config.params = {
      ...config.params,
      _t: new Date().getTime()
    };
    return config;
  },
  (error) => {
    console.error('[authApiClient] Request interceptor error:', error);
    return Promise.reject(error);
  }
);

// Response Interceptor
authApiClient.interceptors.response.use(
  (response) => {
    console.log('[authApiClient] Successful response:', {
      status: response.status,
      url: response.config.url
    });
    return response;
  },
  async (error) => {
    console.log('[authApiClient] Response error intercepted:', {
      status: error.response?.status,
      url: error.config?.url,
      message: error.message
    });

    const originalRequest = error.config;

    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      console.log('[authApiClient] 401 detected, checking retry status:', {
        isRetry: originalRequest._retry,
        originalUrl: originalRequest.url
      });

      originalRequest._retry = true;

      try {
        console.log('[authApiClient] Attempting token refresh');
        const refreshResponse = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/refresh_tokens`,
          {},
          { 
            withCredentials: true,
            headers: {
              'Content-Type': 'application/json'
            }
          }
        );
        console.log('[authApiClient] Token refresh response:', {
          status: refreshResponse.status,
          data: refreshResponse.data
        });

        console.log('[authApiClient] Retrying original request:', {
          method: originalRequest.method,
          url: originalRequest.url
        });
        return authApiClient(originalRequest);

      } catch (refreshError) {
        console.error('[authApiClient] Token refresh failed:', {
          status: refreshError.response?.status,
          message: refreshError.message,
          data: refreshError.response?.data
        });

        const accessToken = Cookies.get('accessToken');
        const refreshToken = Cookies.get('refreshToken');
        console.log('[authApiClient] Current tokens before removal:', {
          hasAccessToken: !!accessToken,
          hasRefreshToken: !!refreshToken
        });

        Cookies.remove('accessToken', { path: '/app' });
        Cookies.remove('refreshToken', { path: '/app' });
        
        console.log('[authApiClient] Cookies removed, redirecting to login');
        window.location.href = '/login';
        return Promise.reject(refreshError);
      }
    }

    console.log('[authApiClient] Error not handled by interceptor:', {
      status: error.response?.status,
      url: error.config?.url
    });
    return Promise.reject(error);
  }
);

// Add event listener for unhandled rejections
window.addEventListener('unhandledrejection', (event) => {
  console.error('[authApiClient] Unhandled Promise Rejection:', {
    reason: event.reason,
    promise: event.promise
  });
});

export default authApiClient;

